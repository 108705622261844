import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Divider, Grid, SxProps, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Transaction } from '@one/api-models/lib/Admin/Transaction/Transaction';
import { TransactionListResponse } from '@one/api-models/lib/Admin/Transaction/TransactionListResponse';

import { ApiError } from 'apiAccess/api-client';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';

import { getCardLogo } from './components/common/CardLogo';
import { TransactionStatus } from './components/TransactionStatus';

interface TableRowItemProps {
  label: string;
  value?: string | number | null;
  labelSx?: SxProps;
  valueSx?: SxProps;
}

export const TransactionDetails = () => {
  const navigate = useNavigate();

  const { formatDate, formatCurrency: formatCurrencyFunc } = useFormat();

  const { addApiError } = useToastMessage();
  const { api } = useApiHelpers();

  const location: any = useLocation();

  const [transaction, setTransaction] = useState<Transaction>();

  const { id: paramsTransactionId } = useParams<{ id: string }>();

  const transactionId = location.state?.transactionId || paramsTransactionId;

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const {
    data: transactionData,
    isFetching,
    status,
  } = useQuery<TransactionListResponse, ApiError>(
    ['transactionData', transactionId],
    () =>
      api.transaction.loadTransactionList({
        partnerKeys: [],
        brandKeys: [],
        statuses: [],
        transactionIds: [transactionId],
        listingCriteria: {
          skip: 0,
          take: 1,
        },
      }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      keepPreviousData: false,
      onError: (error: ApiError) => addApiError(error),
      onSuccess: () => setTransaction(transactionData?.transactions.items[0]),
    },
  );
  useEffect(() => {
    // onSuccess from useQuery is not being called at the initial render
    if (status === 'success') {
      setTransaction(transactionData?.transactions.items[0]);
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <Box p={{ xs: 1, md: 3 }}>
      <LoadingScreen open={isFetching} message={'Loading...'} />
      {transaction ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography sx={{ fontWeight: 700, fontSize: '28px', lineHeight: '36px' }}>
              {formatCurrency(transaction?.amount?.amount, transaction?.amount?.currency)}
            </Typography>
            <Typography sx={{ fontSize: '28px', color: 'text.secondary' }}>{transaction?.amount?.currency}</Typography>
            <TransactionStatus status={transaction?.status} />
          </Box>
          <Divider sx={{ width: '100%', my: 3 }} />
          <Grid container gap={2} direction={{ xs: 'column', md: 'row' }}>
            {transaction?.createdDateTime && (
              <>
                <Grid item>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      pb: 1,
                    }}
                  >
                    Last update
                  </Typography>
                  <Typography>{formatDate(transaction?.createdDateTime, true, 'dd MMM, HH:mm')}</Typography>
                </Grid>
                <Grid item display={{ xs: 'none', sm: 'block' }}>
                  <Divider orientation="vertical" />
                </Grid>
              </>
            )}

            {(transaction?.customer?.firstName || transaction?.customer?.lastName) && (
              <Grid item>
                <Typography
                  sx={{
                    color: 'text.secondary',
                    pb: 1,
                  }}
                >
                  Customer
                </Typography>
                <Typography
                  sx={{ fontWeight: 600, color: '#0071E3', cursor: 'pointer' }}
                  onClick={() => navigate(`/customers/${transaction.customer.memberKey}`, { state: { refetch: true } })}
                >
                  {transaction?.customer?.firstName} {transaction?.customer?.lastName}
                </Typography>
              </Grid>
            )}

            {transaction?.paymentMethod && (
              <>
                <Grid item display={{ xs: 'none', sm: 'block' }}>
                  <Divider orientation="vertical" />
                </Grid>

                <Grid item>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                    }}
                  >
                    Payment method
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    {getCardLogo(transaction?.paymentMethod?.cardBrand)}
                    <Typography mb={1.6} ml={-0.5} sx={{ fontSize: '24px' }}>
                      ....
                    </Typography>
                    <Typography sx={{ ml: 0.5 }}>{transaction?.paymentMethod?.cardLast4Digits}</Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ mt: 5, mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Customer details</Typography>
            <Divider />
          </Box>
          <Table
            sx={{
              maxWidth: '40%',
              '.MuiTableCell-root ': {
                pl: 0,
                py: 1.5,
                border: 0,
              },
            }}
          >
            <TableBody>
              {transaction?.customer?.partnerKey && (
                <TableRowItem label="Partner" value={transaction?.customer?.partnerKey} />
              )}
              {transaction?.customer?.brandKey && (
                <TableRowItem label="Brand" value={transaction?.customer?.brandKey} />
              )}
              {transaction?.customer?.memberKey && (
                <TableRowItem label="Member Key" value={transaction?.customer?.memberKey} />
              )}
              {transaction?.customer?.email && <TableRowItem label="Email" value={transaction?.customer?.email} />}
            </TableBody>
          </Table>
          <Box sx={{ mt: 5, mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Payment details</Typography>
            <Divider />
          </Box>
          <Table
            sx={{
              maxWidth: '20%',
              '.MuiTableCell-root ': {
                pl: 0,
                py: 1.5,
                border: 0,
              },
            }}
          >
            <TableBody>
              <TableRowItem label="Description" value={transaction?.description ? transaction?.description : '—'} />
              <TableRowItem
                label="Processing Fee"
                value={transaction?.processingFee?.amount ? transaction?.processingFee.amount : '—'}
              />
              <TableRowItem
                label="Refunded Date Time"
                value={
                  transaction?.refundedDateTime ? formatDate(transaction?.refundedDateTime, true, 'dd MMM, HH:mm') : '—'
                }
              />
            </TableBody>
          </Table>
          <Box sx={{ mt: 5, mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Payment method</Typography>
            <Divider />
          </Box>
          <Table
            sx={{
              maxWidth: '40%',
              '.MuiTableCell-root ': {
                pl: 0,
                py: 1.5,
                border: 0,
              },
            }}
          >
            <TableBody>
              {transaction?.transactionId && <TableRowItem label="Transaction ID" value={transaction?.transactionId} />}
              {transaction?.transactionId && <TableRowItem label="Order Reference" value={transaction?.orderNumber} />}
              {transaction?.paymentMethod?.cardLast4Digits && (
                <TableRowItem label="Number" value={transaction?.paymentMethod?.cardLast4Digits} />
              )}
              {transaction?.paymentMethod?.cardExpirationMonth && transaction?.paymentMethod?.cardExpirationYear && (
                <TableRowItem
                  label="Expires"
                  value={`${transaction?.paymentMethod?.cardExpirationMonth}/${transaction?.paymentMethod?.cardExpirationYear}`}
                />
              )}
              {transaction?.paymentMethod?.cardBrand && (
                <TableRowItem label="Type" value={transaction?.paymentMethod?.cardBrand} />
              )}
              {transaction?.gatewayName && <TableRowItem label="Payment Provider" value={transaction?.gatewayName} />}
              {transaction?.acquirerReferenceNumber && (
                <TableRowItem label="Acquirer Reference Number" value={transaction?.acquirerReferenceNumber} />
              )}
              {transaction?.gatewayReference && (
                <TableRowItem label="Transaction Reference" value={transaction?.gatewayReference} />
              )}
              {transaction?.subscriptionGatewayReference && (
                <TableRowItem label="Subscription Reference" value={transaction?.subscriptionGatewayReference} />
              )}
            </TableBody>
          </Table>
        </>
      ) : (
        !isFetching && (
          <Typography sx={{ fontWeight: 700, fontSize: '28px', lineHeight: '36px', textAlign: 'center' }}>
            Transaction not found or access denied.
          </Typography>
        )
      )}
    </Box>
  );
};

const TableRowItem: React.FC<TableRowItemProps> = ({ label, value, labelSx = {}, valueSx = {} }) => (
  <TableRow>
    <TableCell>
      <Typography sx={{ color: 'text.secondary', ...labelSx }}>{label}</Typography>
    </TableCell>
    <TableCell>
      <Typography sx={{ ...valueSx }}>{value}</Typography>
    </TableCell>
  </TableRow>
);
